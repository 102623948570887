<!-- /* eslint-disable */-->
<template>
  <v-container>
    <!-- Main Title -->
    <v-row no-gutters>
      <v-col cols="12">
        <h1>삭제 키워드 관리</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 삭제한 키워드 정보를 조회할 수 있습니다. ( 최근 50건만 조회 가능합니다 )<br>
              - 삭제한 정보는 일정 기간 경과 시 자동으로 삭제될 수 있습니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters class="flex-grow-1">
      <v-col cols="12" style="text-align:right; margin:10px 0;">
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="no"
          :items-per-page="9999"
          show-select
          v-model:selected="selected"
          hide-default-footer
        >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>플레이스 목록</v-toolbar-title>
          <v-spacer></v-spacer>
          
          <v-select 
            v-model="searchGroup"
            :items="groups"
            item-text="name"
            item-value="groupNo"
            label="그룹"
            outlined
            dense
            hide-details
            return-object
            single-line
            style="max-width: 150px; margin-right: 10px;"
          ></v-select>
          <v-text-field
            v-model="searchText"
            label="플레이스/키워드 명"
            outlined
            dense
            hide-details
            style="max-width: 250px; margin-right: 10px;"
            @keyup.enter="getContents"
          ></v-text-field>
          <v-btn color="primary" @click="getContents" style="margin-right: 10px;">검색</v-btn>
        </v-toolbar>
      </template>

        <template v-slot:body="{ items }">
          <tbody>
            <template v-for="item in items">
              <tr :key="item.no">
                <td rowspan="2">
                  <v-checkbox
                    v-model="selected"
                    :value="item"
                  ></v-checkbox>
                </td>
                <td rowspan="2">{{ item.groupName }}</td>
                <td>{{ item.keyword }}</td>
                <td>{{ item.placeUrl }}</td>
                <td>{{ item.name }}</td>
                <td>{{ parseInt(item.placeCount).toLocaleString() }} 개</td>
                <td>{{ isNaN(parseInt(item.monthSearchCntPc)) ? item.monthSearchCntPc : parseInt(item.monthSearchCntPc).toLocaleString() }} 회</td>
                <td>{{ isNaN(parseInt(item.monthSearchCntMobile)) ? item.monthSearchCntMobile : parseInt(item.monthSearchCntMobile).toLocaleString() }} 회</td>
                <td>{{ item.createTime }}</td>
                <td>{{ item.rankList[0]?.collect_time || '-' }}</td>
                <td rowspan="2">
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <div style="display: inline-block; padding: 5px; text-align:center; border-right:1px solid #ccc; width:85px;"
                    v-for="rankItem in item.rankList" v-bind:key="JSON.stringify(rankItem)">
                    {{rankItem.date}}<br>
                    {{rankItem.rank}}위<br>
                    <span class="saveCount" v-if="rankItem.save_count != null">저 {{ rankItem.save_count }}<br></span>
                    <span class="blogCafeReviewCount">블 {{ parseInt(rankItem.blog_cafe_review_count.replaceAll(',', '')).toLocaleString() }}</span><br>
                    <span class="visitorReviewCount">방 {{  parseInt(rankItem.visitor_review_count.replaceAll(',', '')).toLocaleString() }}</span><br>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      color="primary"
      variant="tonal"
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
    <report-component ref="reportComponent"></report-component>
    
  </v-container>
</template>

<script>
import { wrapFetch } from '@/config';
import ReportComponent from '/src/components/PlaceReport'

export default {
  components: {ReportComponent},
  data() {
    return {
      snackbar: false,
      snackText: "",
      selected: [],
      headers: [
        { text: '그룹', value: 'groupName' },
        { text: '검색 키워드', value: 'keyword' },
        { text: '플레이스 URL 주소', value: 'placeUrl' },
        { text: '플레이스 업체명', value: 'name' },
        { text: '업체수', value: 'placeCount' },
        { text: '월 조회수(PC)', value: 'monthSearchCntPc' },
        { text: '월 조회수(모바일)', value: 'monthSearchCntMobile' },
        { text: '등록일', value: 'create_time' },
        { text: '최근 체크시간', value: 'collectTime' },
        { text: '', value: 'utillity' },
      ],
      searchText: "",
      searchGroup: "",
      groups: [],
      groupsNoAll: [],
      items: [],
    };
  },
  methods: {
    getContents(){
      let self = this;
      self.items = [];
      self.selected = [];
      self.groups = [
        {name: '전체', groupNo: ""},
        {name: '기본 그룹', groupNo: 1}
      ];
      self.groupsNoAll = [
        {name: '기본 그룹', groupNo: 1}
      ];
      let searchGroup = self.searchGroup.groupNo != undefined ? self.searchGroup.groupNo : "";

      wrapFetch(`/place/deleted?searchText=${self.searchText}&searchGroup=${searchGroup}`, "GET")
      .then((data) => {
        data.placeList.map((e) => {
          e.loading = false;
          self.items.push(e);
        })
        data.placeGroupList.map((e) => {
          self.groups.push({
            name: e.name,
            groupNo: e.groupNo
          })
          self.groupsNoAll.push({
            name: e.name,
            groupNo: e.groupNo
          })
        })
      })
    },
    deleteContent(){
      let self = this;
      if(self.selected.length === 0){
        alert("삭제할 데이터를 선택해주세요.");
        return;
      }

      if(confirm(`정말 선택된 ${self.selected.length}개의 데이터를 삭제하시겠습니까?`)){
        wrapFetch('/place/delete', "POST", self.selected)
        .then(() => {
          self.snackText = "플레이스 삭제가 완료되었습니다.";
          self.snackbar = true;
          self.selected = [];
          self.selected.pop();
          self.getContents();
        })
      }
    },
  },
  mounted() {
    this.getContents();
  }
};
</script>

<style>
.flex-grow-1 {
  flex-grow: 1;
}
v-container.fill-height {
  height: 100vh;
}
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
.blogCafeReviewCount {
  font-size: 0.9em;
  color: #555;
  font-weight: bold;
}
.visitorReviewCount {
  font-size: 0.9em;
  color: #633;
  font-weight: bold;
}
.saveCount {
  font-size: 0.9em;
  color: chocolate;
  font-weight: bold;
}

</style>